.logo {
  width: 75px;
  margin-top: -5px;
}

.summaryContainer {
  //left: 120px;
  //height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #ecf0f1;
  padding-top: 10px;
  z-index: 1000;
}

.summaryItem {
  display: inline-block;
  position: relative;
  text-align: center;
  height: 50px;
  width: 55px;
  vertical-align: middle;
  margin-top: 10px;
  //cursor: pointer;
}

.summaryItem :global(.fa-shopping-bag) {
  color: #95a5ab;
}

@media (max-width: 992px) {
    .summaryItem {
        margin-top: 0.5em;
    }
}

.summaryBadge {
  background-color: #e74c3c;
  color: white;
  position: absolute;
  top: -10px;
  right: -5px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
}


.cartWrapper {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 300ms cubic-bezier(0.325, 0.090, 0.000, 1.280);
}

.cartWrapper.open {
  opacity: 1;
  z-index: 10;
  visibility: visible;
  transform: translateY(0);
}

@media (max-width: 992px) {
    .cartWrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.summaryTitle {
  font-size: 1.5em;
  line-height:1.5em;
  color: #2d3e4f;
  width: 100%;
}

.summarySubtitle {
  color: #95a5a6;
  font-size: 14px;
  line-height: 2.2em;
  display: table;
  width: 100%;
}

.titleContainer {
  flex: 1;
  justify-content: center;
  align-self: center;
  font-size: 25px;
}

.headerTitle {
  text-align: center;
  font-weight: 300;
}
