.orderQuestion {
  font-size: 2.5em;
  font-weight: 300;
  margin: 0px 0px 30px;
}

.phoneInputGroup div {
  display: block !important;
}

.phoneInput{
  width:100% !important;
}