@mixin border-radius {
  -webkit-border-radius: $border_radius;
  -moz-border-radius: $border_radius;
  border-radius: $border_radius;
}

// Buttons
$button_bg: #27ae60;
$button_text: #fff;
$button_radius: 3px;

.button {
  color: #fff;
  border-radius: $button_radius;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $button_bg;
  color: $button_bg;
}

.button:active .button:focus {
  color: $button_bg;
  text-decoration: none;
}

.buttonFilled {
  @extend .button;

  text-decoration: none;
  border: 0;
  background-color: $button_bg;
  color: $button_text;
}

.button:hover {
  background-color: $button_bg;
  color: $button_text;
}

.button.disabled, .button[disabled] {
  opacity: 0.5;
}

.big {
  margin: 0;
  font-size: 1.5em;
  height: auto;
  min-width: 200px;
  max-width: 80%;
}

.full {
  width: 100%;
  max-width: 100%;
}
