.loadingContainer {
  display: flex;
  height: 300px;
  align-items: center;
}

.appointmentDate {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  height: 250px;
}

.appointmentDate td:global(.disabled) {
  color: #e74c3c !important;
}

.appointmentTime {
  margin: 0 auto;
  text-align: center;
  max-width: 300px;
}

.btnTimeSlot {
  width: 100%;
  max-width: 150px;
  margin-bottom: 10px !important;
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 10px !important;
}

.slotBusy {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  margin-top: 5px;
  text-align: right;
  display: inline-block;
  float: right;
}
