.profileContainer {
  max-width: 700px;
  float: none !important;
}

.userEdit input {
  width: 80%;
  display: inline-block;
  float: left;
  margin: -5px 0;
}

.userEdit button {
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.userEdit i {
  padding: 0;
}

.cashIcon {
  color: white;
  border-radius: 50%;
  padding: 4px 5px;
  margin-left: 5px;
}

.buttonWidth {
  width: 100%;
}
.editButtonWidth {
  width: 10%;
  margin-bottom: 10px;
  margin-right: 0px !important;
}
