@import url("https://fonts.googleapis.com/css?family=Lato:300,400,600,700");

@media screen and (max-width: 768px) {
  body {
    font-size: 12px;
  }
}

body {
  font-family: 'Lato', sans-serif !important;
  font-size: 14px;
  color: rgb(102, 102, 102);
}

html, body, #app {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

[data-reactroot] {
  min-height: 100%;
}

.full-width {
  width: 100%;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

.title {
  font-size: 2.5em;
  text-align: center;
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 1em;
  color: rgb(102, 102, 102);
}

.sub-title {
  font-size: 1.3em;
  text-align: center;
  margin: 0;
  color: rgb(102, 102, 102);
}

.box {
  border-radius: 3px;
  background: #fff;
  display: table;
  width: 100%;
  //border: 1px solid #e7eaec;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.no-bg {
  background: none;
}

.no-bg:hover {
  background: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

a {
  cursor: pointer;
}

a[disabled], a.disabled {
  pointer-events: none;
  opacity: 0.8;
}

a[disabled]:hover, a.disabled:hover {
  cursor: not-allowed;
  opacity: 0.8;
}

.m-5 {
  margin: 5px;
}

.m-v-10 {
  margin: 10px 0;
}

.m-10 {
  margin: 10px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}
.m-t-30{
  margin-top:30px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-20 {
  padding: 20px;
}
.p-40 {
  padding: 40px;
}

.p-t-0 {
  padding-top: 0;
}

.p-b-0 {
  padding-bottom: 0;
}

.green {
  color: #39ca74;
}

.red {
  color: #e74c3c;
}

.blue {
  color: #2980b9;
}

.dark-blue {
  color: #2c3e50;
}

.silver {
  color: #bdc3c7;
}

.bg-silver {
  background-color: #bdc3c7;
}

.bg-yellow {
  background-color: #f39c12;
}

.bg-green {
  background-color: #39ca74;
}

.bg-red {
  background-color: #e74c3c;
}

.bg-silver {
  background-color: #bdc3c7;
}

.bg-dark-blue {
  background-color: #2c3e50;
}

.white {
  color: #fff;
}

.bg-blue {
  background-color: #2980b9;
}

.spacer-50 {
  height: 50px;
  display: block;
}

.spacer-30 {
  height: 30px;
  display: block;
}

.spacer-20 {
  height: 30px;
  display: block;
}

img {
  max-width: 100%;
}

.icon-small {
  width: 30px;
  height: 25px;
  font-size: 18px;
  text-align: center;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;


}


.nav > li > a:hover, .nav > li > a:focus {
  background-color: inherit;
}

a, a:focus, a:hover {
  text-decoration: none;
}

button {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button:focus, button:hover, button:active {
  outline: none;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  vertical-align: middle;
}

.form-control {
  box-shadow: initial;
  -webkit-box-shadow: initial;
}


/* Sweet alert overriders */
.swal2-content {
  font-size: 1em !important;
}

.swal2-content h4 {
  font-size: 1.1em !important;
  font-weight: bold;
}

.swal2-spacer {
  margin: 0 !important;
}


.pretty>input[type='checkbox'], .pretty>input[type='radio'] {
  z-index: 999;
}
