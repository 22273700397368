.wrapperContainer {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls {
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 10000000;

  button {
    outline: none;
    background-color: #fff;
    color: #414141;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:active {
      outline: none;
    }
  }
}

.image {
  height: 80vh;
  width: 80vw;
  object-fit: contain;
}
