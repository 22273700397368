// Vertical Resizing  Removed
.verticalTextarea{
  resize: vertical;
  min-height: 100px;
}

.itemGroup {
  padding: 0 7.5px;
  margin-bottom: 15px;
}

.itemGroupTitle {
  font-size: 1.3em;
  font-weight: 300;
  text-align: center;
  margin-top: 0px;
}

.itemTypeContainer {
  padding: 7.5px !important;
  margin: 0 !important;
}

.itemTypeWrap {
  max-width: 250px;
  margin: 0 auto;
  padding: 10px;
}

.itemType {
  border-radius: 3px;
  height: 150px;
  position: relative;
}

.itemDetailButton, .itemDetailButton:hover, .itemDetailButton:focus, .itemDetailButton:active {
  position: absolute;
  right: 0;
  padding: 0;
  color: #1881b2;
  cursor: pointer;
  font-size: 1.2em;
  margin: -10px -5px;
}

.itemImage {
  display: block;
  height: 50px;
  // width: 50px;
  opacity: 0.8;
  margin: 0px auto;
  margin-top: 12px;
  margin-bottom: 20px;
}

.itemName {
  color: #2d3e4f;
  font-size: 1.15em;
  text-align: center;
}

.itemPrice {
  color: #95a5a6;
  font-size: 11px;
  text-align: center;
  line-height: 0px;
}

.itemSizeContainer {
  display: block;
  margin-top: 7px;
}

.itemSizeName {
  display: inline-block;
  padding: 0;
  color: #2d3e4f;
  font-size: 12px;
  line-height: 12px;
}

.itemSizePrice {
  color: #95a5a6;
  font-size: 11px;
}

.itemAddContainer {
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
  width: 85px;
}


.itemAdd {
  display: block !important;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  padding: 0;
  -webkit-backface-visibility: initial !important;
}

.itemAdd:hover {
  padding: 0;
}

@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {-webkit-transform: scale(1.2, 1.2); opacity: 1.0;}
  100% {-webkit-transform: scale(1, 1); opacity: 1.0;}
}

@media screen and (max-width: 320px) {
  .itemGroup {
    padding: 0 2.5px !important;
  }

  .itemTypeContainer {
    padding: 2.5px !important;
  }

  .itemAddContainer {
    width: 60px !important;
  }
}
