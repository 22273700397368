$button_bg: #27ae60;

.summaryTable th, .summaryTable td {
  border: 0 !important;
  font-size: 15px;
  text-align: left;
}

.orderQuestion {
  font-size: 2.5em;
  font-weight: 300;
  margin: 0px 0px 30px;
}

.statusText {
  font-size: 16px;
  padding: 10px;
}

.addCouponCode {
  height: 32px;
}

.addCouponCode input {
  display: inline-block;
  max-width: 150px;
}

.addCouponButton {
  font-size: 13px;
  padding: 5px 10px;
}

.addCouponCode i {
  display: inline-block;
  float: left;
  margin: -2px 0 -10px 10px;
}

.itemPrice {
  padding-top: 20px;
  font-size: 22px;
  font-weight: 700;
}

.itemPriceSmall {
  font-size: 12px;
}

.priceTable th, .priceTable td {
  border: 0 !important;
  font-size: 15px;
  text-align: left;
}

.monthButton {
  display: block;
  width: 100%;
  background-color: #fff !important;
  color: #333 !important;
  text-align: center !important;
  padding: 10px 5px !important;
  overflow: hidden;
}

.selectMonths, .monthButton label {
  margin: 3px 0 0 0 !important;
}

.discountAlert {
  font-size: 1.1em;
  margin: 15px 0 0 0 !important;
}

.monthDiscount {
  color: #fff;
  text-align: center;
}

.payForSubtitle {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
  line-height: 2em;
}

.payForMonth {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}

.btnTimeSlot {
  border: 1px solid #ecf0f1;
  padding: 0 !important;
  border-radius: 5px;
  padding: 15px 5px;
}

.rentBeforeDiscount {
  text-decoration: line-through;
  margin-right: 5px;
}

.paymentMonth {
  border-radius: 3px;
  position: relative;
  display: block !important;
  padding: 10px 0 0 0 !important;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;

  :global(.pretty) {
    position: absolute !important;
    font-size: 1.3em;
    top: 5px;
    left: 5px;
    margin: 0 !important;
    margin: 0 !important;

    i:before {
      background-color: $button_bg !important;
    }
  }
}

.paymentModeQuestion {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
}

.paymentMode {
  display: inline;
  width: 150px;
  border: 1px solid #ecf0f1;
  margin: 5px !important;
  padding: 10px;
  border-radius: 3px;

  :global(.pretty) {
    font-size: 1.1em;
    margin: 0 !important;

    i:before {
      background-color: $button_bg !important;
    }
  }

  p {
    font-size: 1.1em;
    margin: 0px !important;
    margin-top: 10px;
  }

  i:before {
    background-color: $button_bg !important;
  }
}

.itemRow {

  font-size: 1.2em;
  padding: 10px 0px;

  th {
    font-size: 0.9em;
    padding: 8px 0 8px 14px !important;
    font-weight: 400;
  }

  td {
    font-size: 0.9em;
    padding: 8px !important;
  }
}

.itemListSub {

  th, td {
    line-height: 1.2em;
    font-size: 1.2em;
    font-weight: 300;
  }

}

.itemListTotal {

  th, td {
    line-height: 1.5em;
    font-size: 1.5em;
    font-weight: 300;
  }

}

.itemQty {
  color: #a5aebc;
}

@media screen and (max-width: 375px) {
  .paymentMode, .paymentMonth {
    :global(.pretty) {
      font-size: 1em;
    }
  }
}
