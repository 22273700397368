.contactPage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  background-color: white;
  padding: 3rem;
  gap: 2rem;
}

.column h2 {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.contactPart {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.country {
}

.country h2 {
  font-size: 1.5rem;
}

.countryWhatsapp {
}

.countryWhatsapp img {
  width: 3rem;
  height: auto;
}
