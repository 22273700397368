.cartContainer {
  //margin: 20px 0;
  background: white;
  width: 370px;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  border-radius: 3px;
  padding: 20px;
}

.cartContainer:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
}

.cartContainer ul{
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.cartContainer li {
  font-size: 1.2em;
  padding: 10px 0px;
}

.cartContainer li:hover {
  background-color: #f5f5f5;
}

@media (max-width: 992px) {
  .cartContainer {
    width: 100%;
    right: 0px;
    margin: 0 0;
    max-height: calc(100vh - 68px);
    overflow: scroll;
    border-bottom: 1px solid #eee;
  }
}


li.cartTotal {
  line-height: 1.5em;
  font-size: 1.5em;
  font-weight: 300;
  padding: 0;
  margin-bottom: 20px;
}

li.cartTotal .light {
  font-size: 14px;
}

.cartQty {
  color: #a5aebc;
}

.clearCart {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.clearCart i {
  color: #fff;
  padding: 3px 5px !important;
  margin: 0;
}
