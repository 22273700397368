.inputTag {
  width: 50%;
  font-size: 16px;
}
.radioFont {
  font-size: 16px;
}
.btnCustom {
  background-color: #27ae60;
  color: #fff !important;
  padding: 10px !important;
  border: none;
  width: 20%;
  font-size: 16px !important;
}
.btnAlign {
  text-align: center;
}
.btnCustom:hover {
  background-color: #27ae60 !important;
  color: #fff !important;
}
.checkCustom {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
