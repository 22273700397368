.addCardTitle {
  font-size: 2.5em;
  font-weight: 300;
  margin: 0px 0px 30px;
}

.cardTapContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  overflow: hidden;
  background-color: white;
  button {
    float: right;
    margin-top: 5px;
  }
}

.cardImg {
  height: 15rem;
}
