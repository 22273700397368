.btnFacebook {
  box-shadow: none;
  background: url(./images/facebook-login-blue.png) no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  cursor: pointer;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 20px;
  padding-left: 50px;
  display: block;
  margin: 0 auto;
}
