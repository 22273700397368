.storageGridContainer {
  padding: 7.5px;
}

.storageCol {
  text-decoration: none;
  padding: 7.5px !important;
  margin: 0;
}

.storage {
  border-radius: 5px;
  background: #fff;
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  border: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.storageCode {
  color: #222;
  padding: 10px;
  font-size: 14px;
}

.storageNumber {
  color: #7f8c8d;
  font-size: 12px;
  padding-top: 5px;
}

.storageStatus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  color: #fff;
  padding: 3px 5px;
  font-weight: 600;
  opacity: 0.8;
  z-index: 100;
}

.storageImage {
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  margin-top: 10px;
}

.storageImage img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  padding: 1em 0 0 0;
  margin: 0 auto;
}

.selectStorage {
  position: absolute !important;
  bottom: 0;
  left: 0;
  padding: 5px 0 !important;
  margin: 0 !important;
}

.selectModeStatus {
  top: 0 !important;
  right: 0 !important;
}
