.statusText {
  font-size: 16px;
  padding: 10px;
}

.summaryTableContainer {
  padding: 20px 20px 0 20px;
}

.summaryTable th,
.summaryTable td {
  border: 0 !important;
  font-size: 15px;
  text-align: left;
}
