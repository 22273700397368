.progWrap {
  padding: 0 8em 1em;
  display: table;
  width: calc(100% - 125px);
}

@media (max-width: 992px) {
  .progWrap {
    padding: 1em 2em 0;
  }
}

.progBar {
  counter-reset: step;
  padding-left: 0;
  padding-right: 0;
  line-height: initial;
}
.progBar li {
  list-style-type: none;
  width: 33.3%;
  float: left;
  font-size: 1.2em;
  font-weight: 400;
  position: relative;
  text-align: center;
  color: #7d7d7d;
}
.progBar li:before {
  width: 40px;
  height: 40px;
  content: counter(step);
  counter-increment: step;
  font-size: 16px;
  line-height: 35px;
  border: 2px solid #ececec;
  display: block;
  text-align: center;
  margin: 0 auto 5px auto;
  border-radius: 50%;
  background-color: white;
  color: #7d7d7d;
}

@media (max-width: 992px) {
  .progBar li:before {
    width: 30px;
    height: 30px;
    font-size: 13px;
    line-height: 25px;
  }
}

/*
.progBar li.progPhone:before {
    content: '\f095';
}
.progBar li.progOrder:before {
    content: '\f07a';
}
.progBar li.progDatetime:before {
    content: '\f017';
}
.progBar li.progPay:before {
    content: '\f118';
}
.progBar li.progAccount:before {
    content: '\f007';
}
.progBar li.progAddress:before {
    content: '\f124';
}*/

.progBar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  display: block;
  background-color: #ececec;
  top: 20px;
  left: -50%;
  z-index: -1;
}

@media (max-width: 992px) {
  .progBar li:after {
    height: 0px;
  }
}

.progBar li.done {
  color: #00c973;
  cursor: pointer;
}
.progBar li.done:before {
  content: '\f00c' !important;
  border: 2px solid #00c973;
  font: normal normal normal 16px/1 FontAwesome;
  color: #00c973;
  padding: 10px;
}

@media (max-width: 992px) {
  .progBar li.done:before {
    padding: 5px;
  }
}

.progBar li:first-child:after {
  content: none;
}
.progBar li.active {
  color: #00c973;
  cursor: pointer;
}
.progBar li.active:before {
  border-color: #00c973;
  background-color: #00c973;
  color: white;
}

@media (max-width: 992px) {
  .progWrap .progBar li {
    font-size: 0px;
  }
}
