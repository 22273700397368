:root {
  --color: #607d8b;
}

.container {  
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.content {
  padding-bottom: 80px;
}

.code {
  margin: 0;
  color: var(--color);
  letter-spacing: 0.02em;
  font-weight: 300;
  font-size: 15em;
  line-height: 1;
}

.title {
  padding-bottom: 0.5em;
  color: var(--color);
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 2em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1em;
}

.text {
  padding-bottom: 0;
  color: color(var(--color) alpha(50%));
  font-size: 1.125em;
  line-height: 1.5em;
}

@media only screen and (max-width: 280px) {
  .container,
  .text {
    width: 95%;
  }

  .title {
    margin: 0 0 0.3em;
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 0 16px;
  }

  .code {
    font-size: 10em;
  }

  .title {
    font-size: 1.5em;
  }
}
