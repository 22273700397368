@media screen and (max-width: 1024px) {
  .content {
    padding: 0 16px;
  }
}

.summaryTableContainer {
  padding: 20px 20px 0 20px;
}

.summaryTable th,
.summaryTable td {
  border: 0 !important;
  font-size: 15px;
  text-align: left;
}

.paymentType {
  width: 120px;
  text-align: center;
}

.statusText {
  font-size: 16px;
  padding: 10px;
}

.inputTag {
  width: 50%;
  font-size: 16px;
}

.radioFont {
  font-size: 16px;
}

.btnCustom {
  background-color: #27ae60;
  color: #fff !important;
  padding: 10px !important;
  border: none;
  width: 20%;
  font-size: 16px !important;
}

.btnAlign {
  text-align: center;
}

.btnCustom:hover {
  background-color: #27ae60 !important;
  color: #fff !important;
}



/***********************************/
body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.container {
  width: 100%;
  height: 100%;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
