$button_bg: #27ae60;

.itemAddContainer {
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden;
  width: 85px;
}

.itemAdd {
  display: block !important;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  padding: 0;
  -webkit-backface-visibility: initial !important;
}

.itemAdd:hover {
  padding: 0;
}

.itemQuantityChange {
  padding: 5px 15px;
  display: inline-block;
  height: 100%;
  font-size: 1em;
  height: 30px;
  width: 30%;
  padding: 0;
  border-radius: 0;
  -webkit-backface-visibility: initial !important;
}

.itemQuantity {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 40%;
  border-top: 1px solid $button_bg;
  border-bottom: 1px solid $button_bg;
  line-height: 28px;
}

.quantityFloat.itemAddContainer {
  float: right;
  width: 60px;
}

.quantityFloat.itemAddContainer .itemAdd {
  height: 24px;
}

.quantityFloat.itemAddContainer .itemQuantityChange {
  height: 26px;
}

.quantityFloat.itemAddContainer .itemQuantity {
  line-height: 24px;
}


@media screen and (max-width: 320px) {
  .itemAddContainer {
    width: 60px !important;
  }
}
