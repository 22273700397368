.header {
  background-color: #fff;
  margin-bottom: 1em;
  border-bottom: 1px solid #ecf0f1;
  position: fixed;
  z-index: 1000;
  width: 100%;
  min-height: 55px;
  top: 0;
}

.logo {
  width: 75px;
  margin: -10px 0 -10px 10px;
}

.siteTitle {
  text-decoration: none;
  font-size: 1.5em;
  margin: 15px 0 0 15px;
  color: #fff;
}

.navlink {
  color: rgb(102, 102, 102);
  font-size: 1.2em;
  line-height: 3em;
}

.navNumber {
  position: absolute;
  top: 3px;
  font-size: 13px;
  border-radius: 12px;
  color: white;
  padding: 1px 6px 0px 6px;
}

.navlink:hover,
.navlink:focus {
  color: #3acbee !important;
}

.footer {
  height: 3em;
  margin-top: 2em;
}

.navbarToggle {
  position: relative;
  float: right;
  padding: 20px 10px;
  margin-top: 10px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.invoiceFloat{
  right: 325px;
}

@media (min-width: 768px) {

  .invoiceFloat{
    right: 255px;
  }
}

@media (min-width: 1280px) {

  .invoiceFloat{
    right: 320px;
  }
}

@media (min-width: 1536px) {

  .invoiceFloat{
    right: 450px;
  }
}




