.defBtn {
  opacity: 0;
  font-size: 0.7rem;
  border-radius: 15px;
  padding: 0.3rem 0.5rem;
}

.photoButton:hover .defBtn {
  opacity: 1 !important;
}
