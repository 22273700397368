.referralContainer {
  margin: 1em 0;
  padding: 1em 2em;
  border-radius: 5px;
  background-color: #fff;
}

.referralContainer h3 {
  padding: 0.5em 0;
  margin: 0;
  font-size: 2em;
}

.referralContainer p {
  margin: 0;
  padding-bottom: 5px;
  font-size: 1.1em;
}

.referralContainer input {
  opacity: 0;
  height: 1px;
  line-height: 0;
  margin: 0;
  padding: 0;
  display: inherit;
}

.referralContainer button {
  font-size: 1.2em;
  max-width: 100%;
}

.referralContainer span {
  display: block;
  font-size: 12px;
  text-align: center;
}

.requestButton {
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 0.6em;
  text-align: center;
  text-decoration: none;
  margin-right: -0.5em;
}

.infoContainer {
  display: table;
  margin: 1em auto 0 auto;
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.infoText {
  color: #222;
  font-size: 1.1em;
  position: relative;
  padding: 1em 1em 0.6em 1em !important;
  margin: 0 !important;
}

.requestButtonContainer {
  display: block;
  height: 100%;
  padding: 5px !important;
  font-size: 1.1em;
}

.requestButtonContainer button {
  height: 100%;
  display: block;
}

.requestStatus {
  text-align: right;
  padding: 0.8em 0.8em 0 0 !important;
}

.requestStatus input,
.requestStatus span,
.requestStatus label {
  padding: 0 !important;
  margin: 0 !important;
  cursor: default !important;
}
