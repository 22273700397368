.invoice {
  position: relative;
  padding: 2.5em 1em 1em 1em;
  margin: 0 auto 15px auto;
  max-width: 700px;
  overflow: hidden;
}

.invoiceNo {
  color: #222;
  font-size: 1.1em;
  display: inline-block;
}

.invoiceDate {
  color: #222;
  font-size: 1.1em;
  float: right;
}

.invoiceStatus {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  color: #fff;
  padding: 3px 5px;
  font-weight: 600;
  opacity: 0.8;
  z-index: 100;
}

.invoiceTable {
  margin-top: 1em;
}

.invoiceButton {
  display: block;
  margin: 0 auto;
  border: 0;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
  width: 150px;
  font-size: 1.1em;
}
