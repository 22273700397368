.error {
  background-color: #b33a3a;
  color: white;
  font-size: 1rem;
  text-align: center;
  width: 20rem;
  position: fixed;
  bottom: 5rem;
  left: calc(50vw - 10rem);
  z-index: 1000000;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
