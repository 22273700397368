.photobox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

.cancelBtn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  font-size: 1.5rem;
  z-index: 10000;
  svg {
    fill: white;
  }
}
.navBtnprev {
  position: fixed;
  top: 50%;
  left: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  font-size: 1.5rem;
  z-index: 10000;
  svg {
    scale: 2;
    fill: white;
  }
}

.navBtnnext {
  position: fixed;
  top: 50%;
  right: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  font-size: 1.5rem;
  z-index: 10000;
  svg {
    scale: 2;
    fill: white;
  }
}

.defaultBtn {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
