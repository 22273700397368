.skFadingCircle {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: relative;
}
.skFadingCircle .skCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.skFadingCircle .skCircle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  border-radius: 100%;
  -webkit-animation: skCircleFadeDelay 1.2s infinite ease-in-out both;
  animation: skCircleFadeDelay 1.2s infinite ease-in-out both;
}
.skFadingCircle .skCircle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.skFadingCircle .skCircle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.skFadingCircle .skCircle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.skFadingCircle .skCircle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.skFadingCircle .skCircle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.skFadingCircle .skCircle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.skFadingCircle .skCircle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.skFadingCircle .skCircle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.skFadingCircle .skCircle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.skFadingCircle .skCircle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.skFadingCircle .skCircle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.skFadingCircle .skCircle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.skFadingCircle .skCircle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.skFadingCircle .skCircle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.skFadingCircle .skCircle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.skFadingCircle .skCircle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.skFadingCircle .skCircle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.skFadingCircle .skCircle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.skFadingCircle .skCircle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.skFadingCircle .skCircle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.skFadingCircle .skCircle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.skFadingCircle .skCircle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes skCircleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes skCircleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
