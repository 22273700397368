.orderQuestion {
  font-size: 3em;
  font-weight: 300;
  margin: 0px 0px 30px;
}

.statusText {
  font-size: 1.5em;
  font-weight: 300;
  padding: 10px;
}
