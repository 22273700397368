.storage {
  position: relative;
  padding: 1em 1em 0 1em;
  margin: 0 auto;
  max-width: 700px;
}

.storagePhotoContainer {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 150px;
  padding: 0.5em;
  margin-top: 1em;
  border: 1px solid #f1f1f1;
  overflow: hidden;
}

.storagePhotoContainer img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.storagePhotoStatus {
  text-align: right;
  float: right;
}

.storagePhotoStatus span,
.storagePhotoStatus input,
.storagePhotoStatus label {
  padding: 0 !important;
  margin: 0 !important;
}

.addPhoto {
  display: block !important;
  font-size: 1.1em;
  margin: 1em auto !important;
  width: 120px;
}

.addPhoto:hover,
.addPhoto:focus {
  color: #fff !important;
}

.addPhotoInput {
  opacity: 0;
  position: absolute;
  top: 1em;
  text-align: center;
  width: 200px;
  height: 50px;
  cursor: pointer;
}

.deletePhoto {
  position: absolute;
  top: 0;
  right: 5px;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.deletePhoto i {
  color: #fff;
  padding: 3px 5px !important;
  margin: 0;
}

.storageCol {
  text-decoration: none;
  padding: 7.5px !important;
  margin: 0;
}

.storageBox {
  border-radius: 5px;
  background: #fff;
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  border: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.storageCode {
  color: #222;
  padding: 10px;
  font-size: 14px;
}

.storageImage {
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  margin-top: 10px;
}

.storageImage img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  padding: 1em 0 0 0;
  margin: 0 auto;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
